import type { PromiseMethodDecorator } from './Promise';
import type { AsyncFunction } from './Types';
import type { Breadcrumb } from '@sentry/types';
import type { ObservableMap} from 'mobx';

import { observable, action, reaction } from 'mobx';

import { developmentMode, getEnvBoolean } from './ReactScriptHelper';
import { Logger } from './errors/Logger';

import { addBreadcrumb } from '@egr/xbox/utils/errors/Breadcrumb';
import { ErrorLevel } from '@egr/xbox/utils/errors/Types';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export function assert(condition: boolean, message?: string, ...data: Array<unknown>): boolean {
    if (developmentMode) {
        console.assert(condition, message, ...data);
    } else if (!condition) {
        addBreadcrumb({
            category: 'assert',
            message,
            data

        });
    }

    return condition;
}

export function assertNotNull<T>(
    value: T,
    message: string = 'value should not be null or undefined',
    ...data: Array<unknown>
): value is NonNullable<T> {
    return assert(value != null, message, ...data);
}

export function assertNotNullOrEmpty(
    value: string | null | undefined,
    message: string = 'value should not be null, undefined or empty',
    ...data: Array<unknown>
): value is string {
    return assert(isNotNullOrEmpty(value), message, ...data);
}

const LogDebugTimer: boolean = getEnvBoolean('DEBUG_TIMER', false);
const DebugTimerMark: boolean = getEnvBoolean('DEBUG_TIMER_MARK', developmentMode);
const DebugTimerStart: boolean = getEnvBoolean('DEBUG_TIMER_START', developmentMode);

export function printTimer(label: string, mode: 'START' | 'PRINT' | 'END' = 'PRINT'): void {
    if (DebugTimerStart) {
        switch (mode) {
            case 'START':
                console.time(label);
                return;
            case 'END':
                console.timeEnd(label);
                return;
            default:
                console.timeLog(label);
        }
    }
}

const debugTimerMap = new Map<string, number>();
if (developmentMode) {
    (window as {_debugTimerMap?: Map<string, number>})._debugTimerMap = debugTimerMap;
}

function getDebugTimer(label: string, additionalTags?: Record<string, string>): () => number {
    const start: number = Date.now();

    if (developmentMode) {
        debugTimerMap.set(label, (debugTimerMap.get(label) ?? 0) + 1);
    }

    if (DebugTimerMark) {
        performance.mark('debugTimer:start:' + label);
    }

    return (): number => {
        if (DebugTimerMark) {
            performance.mark('debugTimer:end:' + label);
            performance.measure('[DT] ' + label, 'debugTimer:start:' + label, 'debugTimer:end:' + label);
        }

        const duration: number = Date.now() - start;
        if (LogDebugTimer) {
            // FixMe: do not use the `additionalTags` for the context
            // introduce a new argument instead
            const { context = 'debugTimer', ...aTags } = (additionalTags ?? {});
            Logger.log(`[${context}]  ${label}: ${duration}`, Object.keys(aTags).length > 0 ? aTags : '');
        }

        if (developmentMode) {
            const decreasedDebugTimerCounter: number = (debugTimerMap.get(label) ?? 1) - 1;
            if (decreasedDebugTimerCounter === 0) {
                debugTimerMap.delete(label);
            } else {
                debugTimerMap.set(label, decreasedDebugTimerCounter);
            }
        }

        return duration;
    };
}

type DebugTimerBreadcrumbHook = (breadcrumb: Breadcrumb, additionalTags?: Record<string, string>, error?: Error) => Breadcrumb;

function addDebugTimerBreadcrumb(
    label: string,
    duration: number,
    breadcrumbHook: DebugTimerBreadcrumbHook,
    additionalTags?: Record<string, string>,
    error?: Error
): void {
    addBreadcrumb(breadcrumbHook(
        {
            level: error == null ? ErrorLevel.Info : ErrorLevel.Error,
            message: label,
            category: 'debugTimer',
            data: {
                duration,
                errorMessage: error instanceof Error ? error.message : error,
                ...additionalTags
            }
        },
        additionalTags,
        error
    ));
}

let debugTimerIndex = 0;
const activeDebugTimers: Map<number, string> = observable.map(undefined, {deep: false});
export async function withDebugTimer<T = void>(
    label: string,
    cb: () => Promise<T>,
    additionalTags?: Record<string, string>,
    breadcrumbHook: (breadcrumb: Breadcrumb, additionalTags?: Record<string, string>) => Breadcrumb = (breadcrumb) => breadcrumb
): Promise<T> {
    const debugTimerId = debugTimerIndex++;
    activeDebugTimers.set(debugTimerId, label);

    const endTimer: () => number = getDebugTimer(label, additionalTags);
    try {
        const value: T = await cb();
        const duration: number = endTimer();
        addDebugTimerBreadcrumb(label, duration, breadcrumbHook, additionalTags);
        return value;
    } catch (error) {
        const duration: number = endTimer();
        addDebugTimerBreadcrumb(label, duration, breadcrumbHook, additionalTags, error);
        throw error;
    } finally {
        activeDebugTimers.delete(debugTimerId);
    }
}

declare global {
    interface Window {
        _activeDebugTimes: typeof activeDebugTimers;
    }
}
window._activeDebugTimes = activeDebugTimers;

if (getEnvBoolean('DEBUG_TIMER_OVERLAY')) {
    (() => {
        const div: HTMLDivElement = document.createElement('div');
        div.style.width = '100vw';
        div.style.height = '100vh';
        div.style.position = 'absolute';
        div.style.top = '0';
        div.style.left = '0';
        div.style.zIndex = '99999';
        div.style.pointerEvents = 'none';
        div.style.whiteSpace = 'pre';
        div.style.color = 'green';
        document.body.appendChild(div);
        reaction(
            () => activeDebugTimers.values(),
            (labels: IterableIterator<string>) => {
                const content = Array.from(labels).sort().join('\r\n');
                div.innerText = content;
            }
        );
    })();
}

export function wrapDebugTimer<T = void, Args extends Array<unknown> = []>(
    label: string,
    cb: AsyncFunction<T, Args>,
    additionalTags?: Record<string, string>
): AsyncFunction<T, Args> {
    return (...args: Args): Promise<T> => {
        return withDebugTimer(
            label, (): Promise<T> => {
                return cb(...args);
            },
            additionalTags
        );
    };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function methodWithDebugTimer(label: string): PromiseMethodDecorator {
    return (
        target: object,
        propertyKey: string | symbol,
        descriptor: TypedPropertyDescriptor<(...args: Array<any>) => Promise<any>>
    ) => {
        const method: ((...args: Array<any>) => Promise<any>) | undefined = descriptor.value;
        if (method !== undefined) {
            descriptor.value = function(...args: Array<any>): Promise<any> {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const self: TypedPropertyDescriptor<(...args: Array<any>) => Promise<void>> = this;
                return withDebugTimer<any>(label, (): Promise<any> => {
                    return method.apply(self, args);
                });
            };
        }
        return descriptor;
    };
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export type ConsoleLevels = 'log' | 'warn' | 'error' | 'info';
export function wrapConsole(callback: (level: ConsoleLevels, ...data: Array<unknown>) => void): void {
    type ConsoleFunc = (...data: Array<unknown>) => void;
    const wrapConsoleFunc: (name: ConsoleLevels, originFunc: ConsoleFunc) => ConsoleFunc = (name: ConsoleLevels, originFunc: ConsoleFunc): ConsoleFunc => {
        return (...data: Array<unknown>): void => {
            try {
                callback(name, ...data);
            } finally {
                originFunc(...data);
            }
        };
    };

    // eslint-disable-next-line no-console
    console.log = wrapConsoleFunc('log', console.log as ConsoleFunc);
    console.warn = wrapConsoleFunc('warn', console.warn as ConsoleFunc);
    console.error = wrapConsoleFunc('error', console.error as ConsoleFunc);
    console.info = wrapConsoleFunc('info', console.info as ConsoleFunc);
}

export function useConsoleCallback(): VoidFunction {
    return () => {
        if (useConsoleCallback.consoleCreated.get()) {
            return;
        }

        useConsoleCallback.consoleCreated.set(true);
        createConsoleOverlay(2500);
        showDebugFlag({
            key: 'console-overlay',
            value: 'Console Overlay'
        });
    };
}

useConsoleCallback.consoleCreated = observable.box(false);

export function createConsoleOverlay(messageTimeout?: number): void {
    const div: HTMLDivElement = document.createElement('div');
    div.style.width = '100vw';
    div.style.height = '100vh';
    div.style.position = 'absolute';
    div.style.top = '0';
    div.style.left = '0';
    div.style.zIndex = '99999';
    div.style.pointerEvents = 'none';
    div.style.whiteSpace = 'pre';
    div.style.color = 'green';
    document.body.appendChild(div);

    let clearHandle: number | undefined;
    const buffer: Array<string> = [];
    const updateDiv: VoidFunction = (): void => {
        div.innerText = buffer.join('\r\n');
    };
    const removeLastLine: VoidFunction = (): void => {
        if (buffer.length) {
            buffer.length -= 1;
            updateDiv();
            clearHandle = window.setTimeout(removeLastLine, messageTimeout);
        }
    };

    wrapConsole((level: ConsoleLevels, ...data: Array<unknown>): void => {
        if (messageTimeout != null) {
            clearTimeout(clearHandle);
            clearHandle = window.setTimeout(removeLastLine, messageTimeout);
        }

        buffer.length = 9;

        try {
            buffer.splice(0, 0, data.map((value: unknown) => JSON.stringify(value, undefined, 4)).join(' '));
        } catch (e) {
            buffer.splice(0, 0, e.message);
        }

        updateDiv();
    });

        console.clear = ((clearFunction: VoidFunction): VoidFunction => {
        return (): void => {
            buffer.length = 0;
            updateDiv();
            clearFunction();
        };
    })(console.clear);
}

function getTraps<T extends object>(prefix?: string): ProxyHandler<T> {
    const getPath: (key: PropertyKey) => string = (key: PropertyKey): string => {
        return prefix == null ? key.toString() : `${prefix}.${key.toString()}`;
    };

    return {
        get(target: T, key: PropertyKey, receiver: unknown): unknown {
            const value: unknown = Reflect.get(target, key, receiver);
            if (typeof value === 'object' && value !== null) {
                return new Proxy(value, getTraps(getPath(key)));
            }

            Logger.log('get: ' + getPath(key));

            if (typeof value === 'function') {
                return value.bind(target);
            }

            return value;
        },
        set(target: T, key: PropertyKey, value: unknown, receiver: unknown): boolean {
            Logger.log('set: ' + getPath(key));

            return Reflect.set(target, key, value, receiver);
        }
    };
}

export function logPropertyAccess<T extends object>(name: string, value: T): T {
    return new Proxy(value, getTraps<T>(name));
}

type DebugFlagLevel = 'WARNING' | 'INFO';
export interface DebugFlag {
    key: string;
    value: string;
    level?: DebugFlagLevel;
    onClick?: VoidFunction;
}
export const debugFlags: ObservableMap<string, DebugFlag> = observable.map();

export const showDebugFlag: (flag: DebugFlag) => void = action((flag: DebugFlag): void => {
    debugFlags.set(flag.key, flag);
});

/**
 * This function can be used to show a iframe in a debug overlay.
 *
 * @param url The src of the iframe.
 * @returns The return value is a cleanup function which should be used to remove the debug overlay.
 */
export function loadInDebugIframe(url: string, onload: VoidFunction = () => {/**/}): VoidFunction {
    const div: HTMLDivElement = document.createElement('div');
    div.style.position = 'fixed';
    div.style.backgroundColor = 'red';
    div.style.width = '90%';
    div.style.height = '90%';
    div.style.top = '5%';
    div.style.left = '5%';
    div.style.zIndex = '99999';

    const label: HTMLParagraphElement = document.createElement('p');
    label.style.color = '#333333';
    label.innerText = url;

    div.appendChild(label);

    const iframe: HTMLIFrameElement = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = '1px solid green';
    iframe.src = url;
    iframe.onload = onload;

    div.appendChild(iframe);

    document.body.appendChild(div);

    let called: boolean = false;
    return (): void => {
        if (!called) {
            called = true;
            document.body.removeChild(div);
        }
    };
}