import * as React from 'react';

import { loadScript } from '@egr/xbox/utils/Helper';
import { PUBLIC_URL } from '@egr/xbox/utils/ReactScriptHelper';

import { joinPath } from '@easterngraphics/wcf/modules/utils/string';

export const AppLoader: React.FC = () => {
    const [ component, setComponent ] = React.useState(<div/>);

    React.useEffect(
        (): void => {
            void (async (): Promise<void> => {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                if (window['CSG'] == null) {
                    await loadScript(joinPath(PUBLIC_URL + '/w-cf-room/', 'libs', 'csg-js', 'csg.min.js'));
                }

                const { getApp } = await import('./getApp');

                const RealApp: React.FC = await getApp();

                setComponent(React.createElement(RealApp));
            })();
        },
        [] // call only once
    );
    return component;
};