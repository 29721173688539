import * as Lockr from 'lockr';
import isArray from 'lodash/isArray';

import { AppName } from '../Constants';
import { safelyParseJSON } from '../Json';
import { SyncExternalStore } from '../SyncExternalStore';
import { captureException } from '../errors/Error';

import { hasAppApi } from '@egr/xbox/app-api/Communication';

import { ListenableEvent } from '@easterngraphics/wcf/modules/utils';

const reload = new ListenableEvent<void, Window>(window);

export class PersistentStore<State extends {}> extends SyncExternalStore<State> {
    public static deprecatedState: Map<string, unknown> | undefined;

    public static async loadOnStart(deprecatedKey: string = 'app-settings'): Promise<void> {
        if (hasAppApi) {
            try {
                const { get, set, data } = await (await import('./PersistentStorageApp')).createStorage();
                PersistentStore.get = get;
                PersistentStore.set = set;
                PersistentStore.delete = () => { /* TODO */ };
                PersistentStore.deprecatedState = data;
                reload.trigger();
            } catch (error) {
                captureException(error, 'debug');
            }
        } else {
            const value: string | null = Lockr.get(deprecatedKey);
            if (value != null) {
                const data = safelyParseJSON(value);
                if (data != null && isArray(data)) {
                    PersistentStore.deprecatedState = new Map(data);
                }
                Lockr.rm(deprecatedKey);
            }
        }
    }

    private static get(name: string): unknown {
        try {
            const value = localStorage.getItem(`${AppName}.${name}`);
            if (value == null) {
                return {};
            }
            return safelyParseJSON(value) ?? {};
        } catch (e) {
            captureException(e, 'debug');
            return {};
        }
    }

    private static delete(name: string): void {
        try {
            localStorage.removeItem(`${AppName}.${name}`);
        } catch (e) {
            captureException(e, 'debug');
        }
    }

    private static set(name: string, state: unknown): void {
        localStorage.setItem(`${AppName}.${name}`, JSON.stringify(state));
    }

    private static savePermitted = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected static stores = new Map<string, PersistentStore<any>>();
    /**
     * should be called after user submits cookie-dialog
     */
    public static enableSaving() {
        this.savePermitted = true;
        for (const store of this.stores.values()) {
            const content = store._getSaveContent();
            if (Object.keys(content).length) {
                void PersistentStore.set(store.name, store.getSaveContent());
            }
        }
    }

    protected get savePermitted(): boolean {
        return PersistentStore.savePermitted || this._savePermitted;
    }

    protected onReloadTrigger = () => {
        reload.removeListener(this.onReloadTrigger);
        let restoredState: State | undefined;
        let migratedState: Partial<State> | undefined;

        try {
            migratedState = this.migrate?.(PersistentStore.deprecatedState);
        } catch {
            /* */
        }

        const storage = PersistentStore.get(this.name) ?? {};
        if (
            Object.keys(migratedState ?? {}).length ||
            Object.keys(storage).length
        ) {
            restoredState = {
                ...this.initialDefaultState,
                ...migratedState,
                ...storage,
                restored: 'localStorage',
            };
        }
        this.updateState({ ...this.initialState, ...restoredState });
    };

    constructor(
        protected name: string,
        protected initialDefaultState: State,
        protected _savePermitted: boolean = false,
        protected migrate?: (value: Map<string, unknown> | undefined) => Partial<State>,
    ) {
        if (PersistentStore.stores.get(name)) {
            console.warn(`localStorage item ${name} already used`);
        }

        let restoredState: State | undefined;
        let migratedState: Partial<State> | undefined;

        try {
            migratedState = migrate?.(PersistentStore.deprecatedState);
        } catch {
            /* */
        }

        const storage = PersistentStore.get(name) ?? {};
        if (
            Object.keys(migratedState ?? {}).length ||
            Object.keys(storage).length
        ) {
            restoredState = {
                ...initialDefaultState,
                ...migratedState,
                ...storage,
                restored: 'localStorage',
            };
        }
        super(initialDefaultState, restoredState);
        reload.addListener(this.onReloadTrigger);
        this.getSaveContent = this.getSaveContent.bind(this);

        this.subscribe(() => {
            if (this.savePermitted) {
                const content = this._getSaveContent();
                if (Object.keys(content).length) {
                    void PersistentStore.set(name, content);
                } else {
                    void PersistentStore.delete(name);
                }
            }
        });

        PersistentStore.stores.set(this.name, this);
    }

    protected getSaveContent(): Partial<State> {
        const { restored, ...state } = this.snapshot;
        return state as Partial<State>;
    }

    protected _getSaveContent(): Partial<State> {
        const unfiltered = this.getSaveContent();
        const content = {};

        for (const key of Object.keys(unfiltered)) {
            if (
                unfiltered[key] !== this.initialState[key] ||
                this.internalState.restored
            ) {
                content[key] = unfiltered[key];
            }
        }
        return content as Partial<State>;
    }
}