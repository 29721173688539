import type { BrandVariants} from '@fluentui/react-components';
import type * as React from 'react';

import { FluentProvider, createLightTheme, createDarkTheme } from '@fluentui/react-components';
import { createRoot } from 'react-dom/client';

import { AppLoader } from './AppLoader';

import { layoutInformation } from '@egr/xbox/egr-gui-elements/Helper/Darkmode';
import { AppName } from '@egr/xbox/utils/Constants';
import { useSyncState } from '@egr/xbox/utils/SyncExternalStore';

const pCon: BrandVariants = {
    10: '#000000',
    20: '#000000',
    30: '#2F3D0A',
    40: '#40530D',
    50: '#516911',
    60: '#627F14',
    70: '#739518',
    80: '#95C11F',
    90: '#A6D723',
    100: '#B0DE35',
    110: '#B9E24A',
    120: '#C1E660',
    130: '#D2ED8C',
    140: '#DBF0A2',
    150: '#ECF7CE',
    160: '#F5FBE4'
};

export const lightTheme = createLightTheme(pCon);
lightTheme.fontFamilyBase = 'system-ui, "Segoe UI", sans-serif';
const darkTheme = createDarkTheme(pCon);
darkTheme.fontFamilyBase = 'system-ui, "Segoe UI", sans-serif';
darkTheme.colorBrandForeground1 = pCon[110];
darkTheme.colorBrandForeground2 = pCon[120];
document.body.style.fontFamily = 'system-ui, "Segoe UI", sans-serif';

const ThemeProvider: React.FC<React.PropsWithChildren> = (props) => {
    const { darkmode } = useSyncState(layoutInformation, ['darkmode']);

    return (
        <FluentProvider theme={darkmode ? darkTheme : lightTheme}>
            {props.children}
        </FluentProvider>
    );
};

export async function renderApp(): Promise<void> {
    const rootElement = document.getElementById('root');
    document.title = AppName;

    if (rootElement == null) {
        throw new Error('root element not found');
    }

    const root = createRoot(rootElement);

    root.render(
        <ThemeProvider>
            <AppLoader/>
        </ThemeProvider>
    );
}