import { getEnvVar } from './ReactScriptHelper';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

interface VersionInfoProperties {
    version: string;
    commit: string;
    releaseVersion?: ReleaseVersion;
}

type NoneReleaseTags = 'rc' | 'alpha' | 'beta';

export interface ReleaseVersion {
    major: number;
    minor: number;
    patch: number;
    build_type: NoneReleaseTags | 'release';
    build_number: number;
    metaInfo?: string;
}

function parseVersion(version: string): ReleaseVersion | undefined {
    const matches: RegExpExecArray | null = /^(\d+)\.(\d+)\.(\d+)(-(alpha|rc|beta)(\.(\d+))?)?(\+(\d+))?$/.exec(version);
    if (matches == null) {
        return;
    }

    // Note: we could use named groups but since we need to support old browsers we use a workaround instead
    const enum VersionRegexGroups {
        MAJOR = 1, // number
        MINOR = 2, // number
        PATCH = 3, // number or (alpha|beta|rc)(\d+)
        BUILD_TYPE = 5, // undefined or alpha|beta|rc
        BUILD_NUMBER = 7, // undefined or number
        META_INFO = 9 // number
    }

    return {
        major: parseInt(matches[VersionRegexGroups.MAJOR], 10),
        minor: parseInt(matches[VersionRegexGroups.MINOR], 10),
        patch: parseInt(matches[VersionRegexGroups.PATCH], 10),
        build_type: (matches[VersionRegexGroups.BUILD_TYPE] as NoneReleaseTags | undefined) ?? 'release',
        build_number: parseInt(matches[VersionRegexGroups.BUILD_NUMBER] ?? '0', 10),
        metaInfo: matches[VersionRegexGroups.META_INFO]
    };
}

export const VersionInfo: VersionInfoProperties | null = ((): VersionInfoProperties | null => {
    const version: string = getEnvVar('BUILD_VERSION', '');
    const commit: string = getEnvVar('BUILD_COMMIT', 'develop');

    if (version === '') {
        // version parsed from package.json
        if (isNotNullOrEmpty(process.env.VERSION)) {
            return {
                version: process.env.VERSION,
                commit: 'develop',
                releaseVersion: parseVersion(process.env.VERSION),
            };
        }
        return null;
    }

    return {
        version,
        commit,
        releaseVersion: parseVersion(version)
    };
})();
export const VersionLabel: string = VersionInfo?.version ?? 'develop';
export const isReleaseVersion: boolean = VersionInfo?.releaseVersion?.build_type === 'release';

export default VersionInfo;