import type { IObservableArray} from 'mobx';

import { action, observable } from 'mobx';

import { developmentMode, getEnvBoolean, getEnvNumber } from '../ReactScriptHelper';

const maxBufferSize = getEnvNumber('LOGGER_MAX_ENTRIES', 1000);
export const DEBUG_LOG_CONSOLE = getEnvBoolean('DEBUG_LOG_CONSOLE', false) || developmentMode;

type LogLevel = 'info' | 'warning' | 'error';

interface Message {
    time: number;
    level: LogLevel;
    message: string;
}

export const Logger = new class {
    private buffer: IObservableArray<Message> = observable.array();

    private additionalLog: Record<LogLevel, Array<(...args: Array<unknown>) => void>> = {
        'error': [],
        'info': [],
        'warning': [],
    };

    public constructor() {
        this.log = this.log.bind(this);
        this.warn = this.warn.bind(this);
        this.error = this.error.bind(this);
        this.addLogging = this.addLogging.bind(this);
        this.clear = action(this.clear.bind(this));
        this.addMessage = action(this.addMessage.bind(this));

        if (DEBUG_LOG_CONSOLE) {
            (window as {_Logger?: typeof Logger})._Logger = this;
        }
    }

    public addLogging(callback: (...args: Array<unknown>) => void, level: LogLevel): void {
        this.additionalLog[level].push(callback);
    }

    public log(...args: Array<unknown>): void {
        this.addMessage('info', ...args);

        if (DEBUG_LOG_CONSOLE) {
            // eslint-disable-next-line no-console
            console.log(...args);
        }

        for (const callback of this.additionalLog.info) {
            callback(args);
        }
    }

    public warn(...args: Array<unknown>): void {
        this.addMessage('warning', ...args);

        if (DEBUG_LOG_CONSOLE) {
            console.warn(...args);
        }

        for (const callback of this.additionalLog.warning) {
            callback(args);
        }
    }

    public error(...args: Array<unknown>): void {
        this.addMessage('error', ...args);

        if (DEBUG_LOG_CONSOLE) {
            console.error(...args);
        }

        for (const callback of this.additionalLog.error) {
            callback(args);
        }
    }

    /**
     * @returns an observable array
     */
    public getMessages(): ReadonlyArray<Readonly<Message>> {
        return this.buffer;
    }

    public clear(): void {
        this.buffer.clear();
    }

    private addMessage(level: LogLevel, ...args: Array<unknown>): void {
        this.buffer.push({
            level,
            time: Date.now(),
            message: this.formatArgs(...args)
        });

        if (this.buffer.length > maxBufferSize) {
            this.buffer.shift();
        }
    }

    private formatArgs(...args: Array<unknown>): string {
        return args.map((value) => this.convertToString(value)).join(' ');
    }

    private convertToString(value: unknown): string {
        if (value instanceof Error) {
            return `${value.name}: ${value.message}\n${value.stack ?? ''}`;
        }

        switch (typeof value) {
            case 'string':
                return value;
            default:
                return JSON.stringify(value);
        }
    }
};