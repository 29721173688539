import type { PromiseCallbacks} from '../promise/Callbacks';
import type { AjaxRequestInfo } from '@easterngraphics/wcf/modules/utils/async';
import type App  from '@egr/xbox/base-app/App';
import type { SeverityLevel , BrowserOptions} from '@sentry/browser';
import type { BrowserTransportOptions } from '@sentry/browser/build/npm/types/transports/types';
import type { CaptureContext, EventHint, ErrorEvent, Transport, Envelope, Breadcrumb, BreadcrumbHint } from '@sentry/core';

import { addBreadcrumb, makeFetchTransport , captureException as sentryCaptureException, init, setExtra, breadcrumbsIntegration, globalHandlersIntegration, getClient, addEventProcessor, rewriteFramesIntegration, getCurrentScope } from '@sentry/browser';
import merge from 'lodash/merge';
import * as React from 'react';

import { EAIWS_SERVER } from '../Constants';
import { assert } from '../Debug';
import { removeUUIDs } from '../Helper';
import { removeUndefinedValues } from '../Object';
import { developmentMode, getEnvArray, getEnvVar } from '../ReactScriptHelper';
import VersionInfo, { isReleaseVersion } from '../Version';
import { getPromiseCallbacks } from '../promise/Callbacks';
import { getObjectFromQueryString } from '../string/url/getObjectFromQueryString';

import { Logger } from './Logger';
import { fixFrame } from './sentry/FixAppFramesIntegration';

import { appInfo } from '@egr/xbox/app-api/AppInfo';

import { SoapError } from '@easterngraphics/wcf/modules/eaiws';
import { wcfConfig } from '@easterngraphics/wcf/modules/utils';
import { CustomError } from '@easterngraphics/wcf/modules/utils/error';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export function enableSentry(sentryEnabled: boolean): void {
    preventSendSentry = !sentryEnabled;
}

export let preventSendSentry: boolean = true;

export class WrappedError extends CustomError {
    public constructor(error: Error, errorMessage?: string) {
        super(error.name);

        Object.setPrototypeOf(this, WrappedError.prototype);

        this.message = errorMessage ?? error.message;
        this.stack = error.stack;

    }
}

export type ErrorOptions = CaptureContext;

export const errorTrackingEnabled: () => boolean = () => getClient() != null;
let lastEventId: string | undefined = undefined;
export const getLastErrorId: () => string | undefined = () => { return lastEventId; };

export class ErrorWithRavenOptions extends Error {
    public constructor(message?: string, public ravenOptions?: ErrorOptions) {
        super(message);

        Object.setPrototypeOf(this, ErrorWithRavenOptions.prototype);
    }
}

if (developmentMode) {
    (window as { _captureException?: unknown })._captureException = (message?: string, level: 'fatal' | 'error' | 'debug' = 'debug') => {
        captureException(new Error(message), level);
    };
}

getCurrentScope().setTags({ url: appInfo != null ? appInfo.initialization_url : window.location.href});

if (window.location.hash) {
    getCurrentScope().setTags({ hash: window.location.hash});
}

export function updateTag(key: string, value: number | string | boolean | bigint | symbol | null | undefined): void {
    getCurrentScope().setTags({ [key]: value});
}

export function getTags() {
    return getCurrentScope().getScopeData();
}

/**
 * The following level should be uses:
 *
 * - `fatal` if the error can not be handled an the project must be closed
 * - `error` if the error can be handled and a error message/dialog is shown
 * - `debug` if the error can be handled without the user noticing
 */
export function captureException(error: Error, errorLevel: 'fatal' | 'error' | 'debug', options?: ErrorOptions): void {
    if (errorLevel === 'debug') {
        console.warn(error);
    }
    if (sentryHasIgnoreError(error) || (isNotNullOrEmpty(EAIWS_SERVER) && !EAIWS_SERVER.includes('pcon-solutions.com'))) {
        return;
    }

    const level: SeverityLevel = fromString(errorLevel);

    const errorOptions: ErrorOptions = merge<
        ErrorOptions,
        ErrorOptions,
        ErrorOptions,
        ErrorOptions
    >(
        {
            level,
            contexts: {
                hash: getObjectFromQueryString(window.location.hash.slice(1))
            }
        },
        error instanceof SoapError ? {
            fingerprint: [removeUUIDs(error.message).trim(), 'SoapError', error.methodName ?? ''], // soap errors should only be grouped by the error message
            tags: isNotNullOrEmpty(error.methodName) ? {
                soapMethod: error.methodName,
                eaiwsServer: (window as { _App?: App })._App?.eaiwsSession?.baseUrl,
            } : undefined
        } : {},
        options ?? {},
        (error as ErrorWithRavenOptions).ravenOptions ?? {}
    );

    if (errorTrackingEnabled()) {
        sentryCaptureException(error, errorOptions);
    } else {
        Logger.error(error, errorOptions);
    }
}

function fromString(level: SeverityLevel): SeverityLevel {
    switch (level) {
        case 'fatal':
        case 'error':
        case 'warning':
        case 'info':
        case 'debug':
            return level;
        case 'log':
        default:
            return 'log';
    }
}

function getErrorName(error: Error): string {
    // Note: since the js minifier change the code, the error name is mostly useless.
    // This is an attempt to get a useful name based on the error object.

    if (error.constructor === SoapError) {
        return 'SoapError';
    }

    if (error.name === 'DataError') {
        return 'DataError';
    }

    return error.constructor.name;
}

export function getSentryTransportState(error: Error): Promise<boolean> {
    const promise: Promise<boolean> | undefined = sentryTransportLog.get(error);
    if (promise == null) {
        return Promise.resolve(false);
    }

    return promise;
}

function setupImprovedSoapBreadcrumbs(): void {
    if (
        assert(wcfConfig.ajaxResolvedEvent == null, 'ajaxResolvedEvent must be undefined') &&
        assert(wcfConfig.ajaxRejectedEvent == null, 'ajaxRejectedEvent must be undefined')
    ) {
        let methodNameStartPosition: number | null = null;
        const getMethodName: (body: string) => string = (body: string): string => {
            if (methodNameStartPosition == null) {
                // Note: is kind of a ugly hack. A regex could be a better solutions
                // but could also have a bigger impact on the performance. A change to
                // the core would properly be the best solution.

                methodNameStartPosition = body.indexOf(
                    '<', // first opening tag
                    body.indexOf('s:Body') // after the `s:Body` tag
                ) + 1;
            }

            // return string from the beginning of the `methodNameStartPosition` to the
            // first space
            return body.substring(
                methodNameStartPosition,
                body.indexOf(' ', methodNameStartPosition)
            );
        };

        const sessionRegExp = new RegExp('<sessionId[^>]+>(?<id>[a-z0-9-]{36})</');
        const getSessionId = (body: string): string | undefined => {
            return sessionRegExp.exec(body)?.groups?.id;
        };

        const itemRegExp = new RegExp('<itemId[^>]+>(?<id>[a-z0-9-]{36})</');
        const getItemId = (body: string): string | undefined => {
            return itemRegExp.exec(body)?.groups?.id;
        };

        const callback: typeof wcfConfig.ajaxResolvedEvent = (requestInfo: AjaxRequestInfo, xhrRequest: XMLHttpRequest, error?: Error): void => {
            try {
                if (requestInfo.method === 'POST' && requestInfo.url.includes('/EAI/') && requestInfo.options?.dataType === 'xml') {
                    addBreadcrumb({
                        type: 'http',
                        category: 'xhr',
                        data: {
                            url: requestInfo.url,
                            method: requestInfo.method,
                            status_code: xhrRequest.status,
                            soapMethod: getMethodName(requestInfo.data as string),
                            sessionId: getSessionId(requestInfo.data as string),
                            itemId: getItemId(requestInfo.data as string),
                            error: error?.message
                        }
                    });
                }
            } catch (e) {
                Logger.error(e);
            }
        };
        wcfConfig.ajaxResolvedEvent = callback;
        wcfConfig.ajaxRejectedEvent = callback;
    }
}

let lastSentryError: Error | null = null;
addEventProcessor((event, hint?: EventHint) => {
    if (hint?.originalException instanceof Error) {
        lastSentryError = hint.originalException;
    }
    return event;
});

export function getLastException(): Error | null {
    return lastSentryError;
}

const sentryIgnoreList: WeakSet<Error> = new WeakSet<Error>();
const sentryUrl: string = getEnvVar('SENTRY_URL', '');
const eaiwsServer: string = getEnvVar('EAIWS_SERVER', '');

export const ErrorStack = new class ErrorStack {
    private _errors: Array<ErrorEvent> = [];

    public get errors(): Array<ErrorEvent> {
        return this._errors;
    }

    public constructor(private limit: number = 1) { }

    public push(error: ErrorEvent): void {
        this._errors = [error, ...this._errors].slice(0, this.limit);
    }

    public toString(): string {
        return JSON.stringify(this.errors, null, 2);
    }

}();

export function setupSentry(): void {
    if (isNotNullOrEmpty(sentryUrl) || isNotNullOrEmpty(sentryUrl) && eaiwsServer.includes('.eaiws.pcon-solutions.com')) {
        const breadcrumbFilter = getEnvArray('SENTRY_FILTER_BREADCRUMBS');

        if (breadcrumbFilter.length === 0 || breadcrumbFilter.includes('soap') || breadcrumbFilter.includes('xhr')) {
            setupImprovedSoapBreadcrumbs();
        }

        const sentryOptions: BrowserOptions = {
            // source: https://docs.sentry.io/clients/javascript/tips/
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                'Can\'t find variable: ZiteReader',
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
                // Generic error code from errors outside the security sandbox
                // You can delete this if using raven.js > 1.0, which ignores these automatically.
                'Script error.',
                // Avast extension error
                '_avast_submit'
            ],
            denyUrls: [
                // Google Adsense
                /pagead\/js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
            ],
            dsn: sentryUrl,
            sendClientReports: false,
            normalizeDepth: 5,
            autoSessionTracking: false,
            environment: isReleaseVersion ? 'production' : 'development',
            beforeSend: (event: ErrorEvent, hint: EventHint): ErrorEvent | PromiseLike<ErrorEvent | null> | null => {
                lastEventId = event.event_id;
                ErrorStack.push(event);
                if (preventSendSentry) {
                    return null;
                }

                try {
                    const lastError: Error | null = getLastException();
                    const currentError = event.exception?.values?.[0];

                    if (currentError != null) {
                        if (
                            lastError != null &&
                            // should not be necessary, but I want to be on the safe side
                            currentError.type === lastError.name &&
                            currentError.value === lastError.message
                        ) {
                            currentError.type = getErrorName(lastError);
                        }
                    }
                } catch {
                    // ignore
                }

                return event;
            },
            transport: TransportWithLog,
            beforeBreadcrumb: (data: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null => {
                if (breadcrumbFilter.length > 0 && !breadcrumbFilter.includes(data.category ?? '')) {
                    return null;
                }

                if (isSoapRequest(data)) {
                    // filter out the original soap request -> request will be logged via custom logger (soap) and
                    // therefore the breadcrumb entry duplicated
                    return null;
                }
                if (developmentMode && data.category === 'console') {
                    return null;
                }

                if (data?.data !== undefined) {
                    data.data = removeUndefinedValues(data.data);
                }
                if (data?.category === 'ui.click') {
                    const sentryLabel: RegExpExecArray | null = /\[data-sentrylabel=([^\]]*)\]/i.exec(data.message ?? '');
                    if (isNotNullOrEmpty(sentryLabel?.[1])) {
                        data.message = sentryLabel?.[1];
                    }
                    else if (hint?.event != null) {
                        const { target } = hint.event;
                        if (target instanceof HTMLElement) {
                            data.message = getSentryLabel(target) ?? data.message;
                        }
                    }
                }
                return data;
            },
            integrations: [
                rewriteFramesIntegration({
                    prefix: 'pcon://',
                    iteratee: fixFrame
                }),
                globalHandlersIntegration({
                    onerror: true,
                    onunhandledrejection: false
                }),
                breadcrumbsIntegration({
                    console: true,
                    dom: { serializeAttribute: 'data-sentrylabel' },
                    fetch: true,
                    history: true,
                    sentry: true,
                    xhr: true,
                })]
        };

        if (VersionInfo != null) {
            let release: string = VersionInfo.version;
            if (isNotNullOrEmpty(VersionInfo.commit)) {
                release += '-' + VersionInfo.commit;
            }
            sentryOptions.release = release;
        }

        init(sentryOptions);
    }
}

function getSentryLabel(target: HTMLElement | null) {
    if (target == null) {
        return null;
    }
    return target.getAttribute('data-sentrylabel') ?? getSentryLabel(target.parentElement);
}

export function getErrorContext(key: string): undefined | Record<string, string> {
    /*
    const context: {extra?: Record<string, Record<string, string> | undefined>} = Raven.getContext();
    return context?.extra?.[key];
    */
    return undefined;
}

export function setErrorContext(category: string, data: unknown): void {
    setExtra(category, data);
}

export function sentryShouldIgnoreError(error: Error): void {
    sentryIgnoreList.add(error);
}

export function sentryHasIgnoreError(error: Error): boolean {
    return sentryIgnoreList.has(error);
}

export const SentryLabelContext: React.Context<string | undefined | null> = React.createContext<string | undefined | null>(null);

const sentryTransportLog = new WeakMap<Error, Promise<boolean>>();

// Note: some click handlers require a synchronous way to read the state of the transmission
export const sentrySyncTransportLog = new WeakMap<Error, boolean>();

function TransportWithLog(options: BrowserTransportOptions): Transport {
    const {send, ...transport} = makeFetchTransport(options);

    async function sendOverride(request: Envelope) {
        const lastError: Error | null = getLastException();
        const promiseCallbacks: PromiseCallbacks<boolean> = getPromiseCallbacks<boolean>();

        if (lastError != null) {
            sentryTransportLog.set(lastError, promiseCallbacks.promise);
            try {
                const response = await send(request);
                promiseCallbacks.resolve(true);
                sentrySyncTransportLog.set(lastError, true);
                return response;
            } catch (error) {
                promiseCallbacks.resolve(false);
                throw error;
            }
        } else {
            return send(request);
        }
    }

    //eslint-disable-next-line @typescript-eslint/dot-notation
    transport['send'] = sendOverride.bind(transport);

    return transport as Transport;
}

function isSoapRequest(data: Breadcrumb): boolean {
    return data.category === 'xhr' &&
            data?.data?.method === 'POST' &&
            data?.data?.url?.includes('/EAI') &&
            data?.data?.soapMethod == null;
}