import { useEffect, useState, useSyncExternalStore } from 'react';

type SyncExternalListener<State extends {}> = (value: Array<keyof State>) => void;

export function useSyncState<State extends {}, Keys extends keyof State>(store: SyncExternalStore<State>, keys: Array<keyof State>): Pick<State, Keys> {
    const [ state, setState ] = useState(store.snapshot);

    useEffect(
        () => {
            setState(store.snapshot);
            return store.subscribe((changedKeys) => {
                if (changedKeys.find((value: keyof State) => keys.includes(value))) {
                    setState(store.snapshot);
                }
            });
        },
        []
    );

    return state;
}

export class SyncExternalStore<State extends {}> {
    private listeners: Array<SyncExternalListener<State>> = [];
    protected internalState: State & { restored?: string; };

    /** in React.FC use useSyncState (hook) instead */
    public get snapshot(): State & { restored?: string; }{
        return this.internalState;
    }

    constructor(public readonly initialState: State, restoredState?: State) {
        this.internalState = { ...initialState, ...restoredState };
        this.getSnapshot = this.getSnapshot.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.updateState = this.updateState.bind(this);
        this.emitChange = this.emitChange.bind(this);
    }

    public subscribe(listener: SyncExternalListener<State>): () => void {
        this.listeners = [...this.listeners, listener];
        return () => {
            this.listeners = this.listeners.filter(l => l !== listener);
        };
    }

    public updateState(state: Partial<State>): void {
        this.internalState = {...this.internalState, ...state };
        this.emitChange(state);
    }

    public getSnapshot(): State {
        return this.internalState;
    }

    protected emitChange(state: Partial<State>): void {
        for (const listener of this.listeners) {
            listener(Object.keys(state) as Array<(keyof State)>);
        }
    }

    /** use only for debug components */
    public hook = () => {
        return useSyncExternalStore<State>(this.subscribe, this.getSnapshot);
    };
}