import { notNull } from './Types';

import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export function openLink(url: string, target: string = '_blank'): void {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.target = target;
    a.click();
}

/**
 * Strips the trailing slash from the given url.
 */
export function stripTrailingSlash(value: string): string;
export function stripTrailingSlash(value: string | null | undefined): string | null | undefined;
export function stripTrailingSlash(value: string | null | undefined): string | null | undefined {
    if (isNullOrEmpty(value)) {
        return value;
    }
    return value.replace(/\/$/, '');
}

/**
 * Strips the query string from the given url.
 */
export function stripQueryString(url: string): string {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.search = '';

    const value: string = a.href;
    if (value.endsWith('?')) { // Fix for IE/EDGE
        return value.substring(0, value.length - 1);
    }

    return value;
}

/**
 * Strips the hash part from the given url.
 */
export function stripHash(url: string): string {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.hash = '';

    const value: string = a.href;
    if (value.endsWith('#')) {
        return value.substring(0, value.length - 1);
    }

    return value;
}

/**
 * Extracts the query string from any given url.
 */
function getQueryString(url: string): string {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    return a.search.slice(1);
}

/**
 * Generates an object with key value pairs from an url encoded query string.
 * @deprecated use `URLSearchParams` or `getObjectFromQueryString` instead
 */
export function parseQueryString(query: string): Record<string, string> {
    // http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    let match: RegExpExecArray | null;
    const pl: RegExp = /\+/g;  // Regex for replacing addition symbol with a space
    const search: RegExp = /([^&=]+)=?([^&]*)/g;
    const decode: (s: string) => string = (s: string) => {
        return decodeURIComponent(s.replace(pl, ' '));
    };

    const urlParams: Record<string, string> = {};
    while ((match = search.exec(query)) != null) {
        urlParams[decode(match[1])] = decode(match[2]);
    }

    return urlParams;
}

/**
 * Generates an url encoded query string from an object with key
 * value paris.
 */
export function buildQueryString(parameters: Record<string, string | number | undefined | null>, skipEmpty: boolean = false): string {
    return Object.keys(parameters).map((key: string): string | null => {
        const value: string | number | undefined | null = parameters[key];
        if (value != null && value !== '') { // Note: we cant use isNotNullOrEmpty because numbers are not allowed
            return encodeURIComponent(key) + '=' + encodeURIComponent(value.toString());
        }

        return skipEmpty ? null : encodeURIComponent(key);
    }).filter(notNull).join('&');
}

export function extendQueryString(url: string, data: Record<string, string>): string {
    return stripQueryString(url) + '?' + buildQueryString(
        Object.assign(
            {},
            data,
            parseQueryString(
                getQueryString(url)
            )
        )
    );
}

export const unloadChecks: Array<() => boolean> = [];

export let disableUnloadChecks: boolean = false;

export function setUnloadCheckDisabled(value: boolean): void {
    disableUnloadChecks = value;
}

window.addEventListener('beforeunload', (event: BeforeUnloadEvent): void => {
    if (disableUnloadChecks) {
        return;
    }

    let block: boolean = false;

    unloadChecks.forEach(
        (value: () => boolean): void => {
            if (!block && value()) {
                block = true;
            }
        }
    );

    if (block) {
        event.preventDefault();
         // Chrome requires returnValue to be set.
        event.returnValue = '';
    }
});

export function isBlobUrl(value: string | null): value is string {
    return (
        isNotNullOrEmpty(value) &&
        value.startsWith('blob:')
    );
}