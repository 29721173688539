// Note: we avoid `new(...) => ...` in order to allow the use of this type
// with classes with protected or private constructors
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export type ClassType<T> = Function & { prototype: T };

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ProperyGetter<Type, Property extends keyof Type> = (value: Type) => Type[Property];

export type AsyncFunction<T = void, Args extends Array<unknown> = []> = (...args: Args) => Promise<T>;

export type JsonSerializable = string | number | boolean | null | { [property: string]: JsonSerializable } | Array<JsonSerializable>;

export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

type PropertyNamesWhichAllowUndefinedAsValue<T> = {[P in keyof T]: undefined extends T[P] ? P : never}[keyof T];

/**
 * This type can be used when optional properties (`prop?: ...`) should to be specified explicitly.
 *
 * Note: the `Required<T>` type removes the `undefined` as allowed value.
 */
export type ForceUndefinedForOptionalValues<T> = Omit<
    T, PropertyNamesWhichAllowUndefinedAsValue<T>
> & {
    [P in PropertyNamesWhichAllowUndefinedAsValue<T>]: T[P]
};

export function notNull<T>(value: T | null | undefined): value is T {
    return value != null;
}

export function isComponentWithContent(value: React.ReactNode | undefined): boolean {
    return value != null && value !== '';
}

export type DivReactFC = React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;