import { captureException } from './errors/Error';

export function safelyParseJSON<T> (content: string): T | undefined {
    try {
        // eslint-disable-next-line no-restricted-properties
        return JSON.parse(content);
    } catch (error) {
        captureException(error, 'debug');
    }

    return undefined;
}