import { getEnvBoolean } from './ReactScriptHelper';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

declare global {
    interface Window {
        MSInputMethodContext: unknown;
    }
    interface Document {
        documentMode: unknown;
    }
}

const userAgent: string = navigator.userAgent.toLocaleLowerCase();

declare global {
    interface Window {
        MSStream?: object;
    }
}

export const android: boolean = /android/.test(userAgent);
export const ios: boolean =
    (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // iPad with iOS >= 13
export const isMobileDevice: boolean = ios || android;
export const safari: boolean =
    userAgent.includes('safari') &&
    !userAgent.includes('chrome') && // old Chrome
    !userAgent.includes('crios') && // new Chrome
    !userAgent.includes('edgios') && // Edge with Chromium
    !userAgent.includes('fxios'); // Firefox
/**
 * Old Edge without chromium
 */
export const isEdge: boolean = userAgent.indexOf('edge') > -1;
export const isFirefox: boolean = userAgent.indexOf('firefox') !== -1;

function minimumIOSVersion(value: number) {
    try {
        const regex = /OS ([0-9_]*) like Mac OS X/gm;
        const result = regex.exec(navigator.userAgent);
        if (result?.[1]) {
          const parts = result?.[1].split('_');
          return parseInt(parts[0], 10) >= value;
        }
    } catch {
        /* */
    }
    return false;
}

/** see issue apps/pcon/wcf-ui/x#1955 */
export const ios16orHigher = minimumIOSVersion(16);

export const inPlanner: boolean = userAgent.indexOf('w-cclient') !== -1 && userAgent.toLocaleLowerCase().indexOf('p-pl-x') !== -1;
const isWKWebkit: boolean = Boolean(window.webkit?.messageHandlers);

/**
 * code based on https://github.com/google/model-viewer/blob/master/packages/model-viewer/src/constants.ts
 */
export const iOSQuicklookArAvailable: boolean = (
    ios &&
    (
        (!isWKWebkit && (document.createElement('a').relList?.supports?.('ar') ?? false) ) ||
        (isWKWebkit && Boolean(/CriOS\/|EdgiOS\/|FxiOS\/|GSA\/|DuckDuckGo\//.test(navigator.userAgent)))
    )
);

export function mediaQueryMatch(query: string): boolean {
    return window.matchMedia(query).matches;
}

export function deviceHasMouse(): boolean {
    return mediaQueryMatch('(pointer: fine), (any-pointer: fine)') && !isMobileDevice;
}

export function isTouchDevice(): boolean {
    return window.matchMedia('(any-pointer: coarse)').matches;
}

export function inIframe(): boolean {
    // browsers can block access to window.top due to same origin policy.
    try {
        return getEnvBoolean('DEBUG_IFRAME', false) || (window.self !== window.top);
    } catch {
        return true;
    }
}

export function getIOSVersion(): number {
    const agent: string = window.navigator.userAgent;
    const start: number = agent.indexOf('OS ');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1 ) && start > -1 ) {
        return window.Number( agent.substr( start + 3, 3 ).replace('_', '.'));
    }
    return 0;
}

export function getLanguage(): string {
    if (navigator.languages != null && isNotNullOrEmpty(navigator.languages[0])) {
        return navigator.languages[0];
    }

    return navigator.language;
}