import type { ErrorOptions , ErrorWithRavenOptions } from '@egr/xbox/utils/errors/Error';

import { registerLogHandler, _log } from './LogHandler';
import { registerHandler } from './MessageManager';

import {type ReactNativeApp, isReactNativeApp, DEBUG_FAKE_APP } from '@egr/xbox/app-api/AppDetection';
import { removeUndefinedValues } from '@egr/xbox/utils/Object';
import { getEnvBoolean, developmentMode } from '@egr/xbox/utils/ReactScriptHelper';
import { addBreadcrumb } from '@egr/xbox/utils/errors/Breadcrumb';
import { Logger } from '@egr/xbox/utils/errors/Logger';
import { ErrorLevel } from '@egr/xbox/utils/errors/Types';

import { CustomError } from '@easterngraphics/wcf/modules/utils/error';
import { isObject } from '@easterngraphics/wcf/modules/utils/object';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export interface BaseMessage {
    type: string;
    action: string;
}

export interface RawBaseMassage extends BaseMessage {
    transaction_id: number;
}

export interface Message<T> extends BaseMessage {
    arguments: T;
}

interface WKMessageHandler {
    postMessage: (message: object, target: string) => void;
}

declare global {
    interface Window {
        droid?: {
            postMessage: (message: string) => void;
        };
        webkit?: {
            messageHandlers: {
                api?: WKMessageHandler,
                fs: WKMessageHandler,
                share: WKMessageHandler,
                progress: WKMessageHandler,
                project: WKMessageHandler
            }
        };
    }
}

export abstract class AppError extends CustomError {
    public static override stackTraceLimit: number = 0;
    public static override captureStackTrace = () => {return; };

    constructor(message?: string, public readonly ravenOptions?: ErrorOptions) {
        super(message);

        Object.setPrototypeOf(this, AppError.prototype);
    }
}

interface AppErrorConstructor {
    // captureStackTrace, prepareStackTrace, stackTraceLimit are added to avoid issues when @types/node version of Error is used
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    captureStackTrace: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareStackTrace?: any;
    stackTraceLimit: number;

    readonly prototype: AppError;
    new(message: string, ravenOptions?: ErrorOptions): AppError;
}

const AppErrors: Map<string, AppErrorConstructor> = new Map<string, AppErrorConstructor>();
const AppErrorsReversed: Map<AppErrorConstructor, string> = new Map<AppErrorConstructor, string>();

export function registerAppError(uuid: string): (constructor: AppErrorConstructor) => AppErrorConstructor {
    return (constructor: AppErrorConstructor): AppErrorConstructor => {
        if (AppErrors.has(uuid)) {
            throw new Error('AppError uuid already taken');
        }

        AppErrors.set(uuid, constructor);
        AppErrorsReversed.set(constructor, uuid);

        return constructor;
    };
}

export class UnknownAppError extends AppError {
    constructor(message?: string, ravenOptions?: ErrorOptions) {
        super(message, ravenOptions);

        Object.setPrototypeOf(this, UnknownAppError.prototype);
    }
}

function getAppError(message?: string, ravenOptions?: ErrorOptions): AppError {
    if (isNotNullOrEmpty(message)) {
        const [uuid, ...msg] = message.split(':');

        const constructor: AppErrorConstructor | undefined = AppErrors.get(uuid);
        if (constructor !== undefined) {
            // Note: the javascript split function is stupid (compared to the python split function)
            // -> so we have to use a join (or a regex instead of the split function), because the
            // limit parameter does not work as expected (and therefor is not really useful at all)
            return new constructor(msg.join(':').trim(), ravenOptions);
        }
    }

    return new UnknownAppError(message, ravenOptions);
}

export function getAppErrorMessage(constructor: AppErrorConstructor, message: string): string {
    const uuid: string | undefined = AppErrorsReversed.get(constructor);
    if (uuid !== undefined) {
        return `${uuid}: ${message}`;
    }

    return message;
}

// ToDo: remove the default timeout
export const MessageTimeout: number = 0x7FFFFFFF; // This value is used to effectively disable the timeout.

export interface RequestProgress {
    time: number;
    request_id: number;
}

interface PromiseMapping {
    resolve: (data: {} | null) => void;
    reject: (data: Error) => void;
    ravenOptions: ErrorOptions;
    resetTimeout: VoidFunction;
    updateProgress: (data: RequestProgress) => void;
    timeout: number;
}

export interface SendMessageCallbacks<RequestProgressType extends RequestProgress> {
    progress?: (data: RequestProgressType) => void;
    transactionId?: (transactionId: number) => void;
}

let TransactionIdCounter: number = 0;
const PromiseQueue: Map<number, PromiseMapping> = new Map<number, PromiseMapping>();
if (developmentMode) {
    const openAppRequests = new Map<number, BaseMessage>();
    (window as {_debugOpenAppRequests?: Map<number, BaseMessage>})._debugOpenAppRequests = openAppRequests;
    registerLogHandler((msg: BaseMessage & {transaction_id?: number}): void => {
        if (msg.transaction_id == null || msg.transaction_id < 0) {
            return;
        }

        switch (msg.type) {
            case 'reply':
                openAppRequests.delete(msg.transaction_id);
                break;
            case 'progress':
                return;
            default:
                openAppRequests.set(msg.transaction_id, msg);
                break;
        }
    });
}

let SelfTalk: boolean = false;
export function enableSelfTalk(): void {
    SelfTalk = true;
}

if (getEnvBoolean('DEBUG_BREADCRUMB_ADD_RAW_MESSAGE', false)) {
    registerLogHandler((message: BaseMessage): void => {
        addBreadcrumb({
            category: 'raw-message',
            message: JSON.stringify(message)
        });
    });
}

const LogRawMessage: boolean = getEnvBoolean('DEBUG_ERROR_LOG_RAW_MESSAGE', developmentMode);
const TraceMessages: boolean = getEnvBoolean('DEBUG_TRACE_APP_COMMUNICATION', false);
const AddExtendedAppApiData: boolean = getEnvBoolean('DEBUG_BREADCRUMB_ADD_EXTENDED_APP_API_DATA', false);

function isDictLike(args: unknown): args is Record<string, unknown> {
    return typeof args === 'object' && args != null && !Array.isArray(args);
}

function getDebugProperties(args: unknown): Record<string, unknown> {
    if (isDictLike(args)) {
        return removeUndefinedValues({
            // we are poking around for useful debug information
            path: args?.path,
            src: args?.src,
            source: args?.source,
            target: args?.target,
            destination: args?.destination
        });
    }

    return {};
}

function addAppApiBreadcrumb(message: BaseMessage, duration: number, error?: unknown, value?: unknown): void {
    // Note: the file messages are logged via the helper function in order
    // to reduce the noise due to the low level api requests
    if (
        message.type === 'fs' && (
            message.action === 'open' ||
            message.action === 'read' ||
            message.action === 'write' ||
            message.action === 'close' ||
            message.action === 'show_fs_root'
        )
    ) {
        return;
    }

    addBreadcrumb({
        category: 'app-api',
        level: error == null ? ErrorLevel.Info : ErrorLevel.Debug,
        message: `${message.type}.${message.action}`,
        data: {
            duration,
            errorMessage: error instanceof Error ? error.message : error,
            request: AddExtendedAppApiData ? message : getDebugProperties((message as {arguments?: unknown}).arguments),
            response: AddExtendedAppApiData ? value : undefined
        }
    });
}

function getTracedPromise<T>(
    message: BaseMessage,
    executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: unknown) => void) => void
): Promise<T> {
    const startTime: number = Date.now();
    return new Promise<T>((resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: unknown) => void): void => {
        executor(
            (value: T | PromiseLike<T>): void => {
                const duration: number = Date.now() - startTime;
                if (TraceMessages) {
                    Logger.log(`${message.type}.${message.action} (succeeded): ${duration}`, {request: message, response: value});
                }

                addAppApiBreadcrumb(message, duration, undefined, value);

                resolve(value);
            },
            (reason?: unknown): void => {
                const duration: number = Date.now() - startTime;
                if (TraceMessages) {
                    Logger.log(`${message.type}.${message.action} (failed): ${duration}`, {request: message, error: reason});
                }

                addAppApiBreadcrumb(message, duration, reason);

                reject(reason as Error);
            }
        );
    });
}

function getRawMessageArguments(rawMessage: RawBaseMassage): undefined | Record<string, string | undefined> {
    const args: unknown = (rawMessage as {arguments?: unknown}).arguments;
    return isObject(args) ? args as Record<string, string | undefined> : undefined;
}

type ValidMessage = RawBaseMassage | {transaction_id: number, type: 'reply', error: boolean, data: unknown};
function _sendMessage(rawMessage: ValidMessage) {
    if (isReactNativeApp) {
        (window as ReactNativeApp).ReactNativeWebView?.postMessage(JSON.stringify(rawMessage));
    } if (SelfTalk) {
        _fakeMessage(rawMessage);
    }
}

export function sendMessage<MessageType extends BaseMessage = BaseMessage, ReplyType = void, ProgressType extends RequestProgress = RequestProgress>(
    message: MessageType,
    noReply?: false,
    messageTimeout?: number,
    callbacks?: SendMessageCallbacks<ProgressType> | null): Promise<ReplyType>;
export function sendMessage<MessageType extends BaseMessage = BaseMessage, ReplyType = void, ProgressType extends RequestProgress = RequestProgress>(
    message: MessageType,
    noReply: true,
    messageTimeout?: number,
    callbacks?: SendMessageCallbacks<ProgressType> | null): ReplyType;
export function sendMessage<MessageType extends BaseMessage = BaseMessage, ReplyType = void, ProgressType extends RequestProgress = RequestProgress>(
    message: MessageType,
    noReply: boolean = false,
    messageTimeout: number = MessageTimeout,
    callbacks: SendMessageCallbacks<ProgressType> | null = null
): Promise<ReplyType> {
    const rawMessage: RawBaseMassage = (message as {} as RawBaseMassage);

    if (noReply) {
        rawMessage.transaction_id = -1;
    } else {
        // Note: it is specified, that the id has to end with 0
        rawMessage.transaction_id = TransactionIdCounter++ * 10;
    }

    if (callbacks?.transactionId != null) {
        callbacks.transactionId(rawMessage.transaction_id);
    }

    if (isReactNativeApp) { // Note: the message from the debug api are already logged vie the `received` handler
        _log(rawMessage);
    }

    return getTracedPromise(rawMessage, (resolve: (data: ReplyType) => void, reject: (error: Error) => void) => {
        if (noReply === false) {
            const error: ErrorWithRavenOptions = new Error('Message timeout reached!');
            const args: undefined | Record<string, string | undefined> = getRawMessageArguments(rawMessage);
            const ravenOptions: ErrorOptions = {
                extra: {
                    message: LogRawMessage ? rawMessage : {
                        type: rawMessage.type,
                        action: rawMessage.action,
                        ...getDebugProperties(args)
                    }
                }
            };
            error.ravenOptions = ravenOptions;

            PromiseQueue.set(rawMessage.transaction_id, {
                resolve: resolve as (data: {} | null) => void,
                reject: reject,
                ravenOptions,
                timeout: window.setTimeout(
                    (): void => {
                        reject(error);
                    },
                    messageTimeout
                ),
                resetTimeout: (): void => {
                    const mapping: PromiseMapping | undefined = PromiseQueue.get(rawMessage.transaction_id);
                    if (mapping != null) {
                        clearTimeout(mapping.timeout);
                        mapping.timeout = window.setTimeout(
                            (): void => {
                                reject(error);
                            },
                            messageTimeout
                        );
                    }
                },
                updateProgress: ((data: ProgressType): void => {
                    const mapping: PromiseMapping | undefined = PromiseQueue.get(rawMessage.transaction_id);
                    if (mapping != null) {
                        mapping.resetTimeout();

                        if (callbacks?.progress != null) {
                            callbacks.progress(data);
                        }
                    }
                }) as (data: RequestProgress) => void
            });
        }

        _sendMessage(rawMessage);
        if (noReply === true) {
            // Note: workaround to avoid null checks in every caller
            resolve(undefined!);
        }
    });
}

export const hasAppApi: boolean = isReactNativeApp || DEBUG_FAKE_APP;

function replyMessageHandler(event: MessageEvent): void {
    if (event.data.transaction_id != null) {
        const source: PromiseMapping | undefined = PromiseQueue.get(event.data.transaction_id);
        if (source != null) {
            if (event.data.error === true) {
                source.reject(getAppError(event.data.data, source.ravenOptions));
            } else {
                source.resolve(event.data.data);
            }

            clearTimeout(source.timeout);

            PromiseQueue.delete(event.data.transaction_id);
        }
    }
}

registerHandler({
    type: 'reply',
    callback: replyMessageHandler
});

function progressMessageHandler(event: MessageEvent): void {
    if (
        event.data.action == null ||
        event.data.arguments == null
    ) {
        return;
    }

    const source: PromiseMapping | undefined = PromiseQueue.get(event.data.arguments.request_id);
    switch (event.data.action) {
        case 'request':
            source?.updateProgress(event.data.arguments as RequestProgress);
            break;
        case 'keep_alive':
            source?.resetTimeout();
            break;
        default:
            break;
    }
}

registerHandler({
    type: 'progress',
    callback: progressMessageHandler,
    allowMultipleHandlers: true
});

/** @internal */
export function _sendReply(
    transactionId: number,
    error: boolean,
    data: object | null | string | number | void = null
): void {
    _sendMessage({
        transaction_id: transactionId,
        type: 'reply',
        error: error,
        data: data instanceof Error ? data.message : data,
    });
}

/** @internal */
export function _fakeMessage<T extends ValidMessage = ValidMessage>(message: T): void {
    window.postMessage(message, '*');
}

if (getEnvBoolean('DEBUG_APP_COMMUNICATION')) {
    registerLogHandler((msg: BaseMessage): void => {
        Logger.log(msg);
    });
}